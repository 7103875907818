// import { useEffect } from 'react';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { Button, Card, CloseButton, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useGetOrderDetailsQuery } from '../../../slices/ordersApiSlice';
import celebration from '../../../assets/img/animated-icons/celebration.json';

const OrderCreateSuccessScreen = () => {
	const { id: orderId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const { data: order, isLoading, error } = useGetOrderDetailsQuery(orderId);

	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);
	return isLoading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'></Message>
	) : (
		<>
			<Row className='g-3'>
				<Col xs={12}>
					<Card className='mb-1'>
						<Card.Body>
							<Row className='flex-between-center'>
								<Col md>
									<h5 className='my-2 mb-md-0 me-1'>订单编号 # {order.orderId}</h5>
								</Col>
								<Col xs='auto'>
									<Button variant='link' className='text-warning fw-medium p-0 me-3' onClick={() => navigate(`/${userInfo.role}/orderlist`)}>
										跟进订单
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={12}>
					<Card>
						<Card.Header>
							<Card.Title className='mb-0'>订单详情</Card.Title>
						</Card.Header>
						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item key={order.orderId}>
										<Row>
											<Col>
												<p>
													<strong>客户名称: </strong>
													{order.customerInfo.customerName}
												</p>
												{order.customerInfo.staffName && (
													<p>
														<strong>联系人: </strong>
														{order.customerInfo.staffName}
													</p>
												)}
												{order.customerInfo.staffPhone && (
													<p>
														<strong>电话: </strong>
														{order.customerInfo.staffPhone}
													</p>
												)}
												{order.customerInfo.staffWechat && (
													<p>
														<strong>微信: </strong>
														{order.customerInfo.staffWechat}
													</p>
												)}
												{order.customerInfo.staffEmail && (
													<p>
														<strong>邮箱: </strong>
														<a href={`mailto:${order.customerInfo.staffEmail}`}>{order.customerInfo.staffEmail}</a>
													</p>
												)}
												<p>
													<strong>地址: </strong>
													{order.customerInfo.customerAddress}
												</p>
											</Col>
											<Col>
												<ListGroup variant='flush'>
													<ListGroup.Item>
														<p>
															<strong>业务联系人: </strong>
															{order.saleUser ? order.saleUser.name : order.createdUser.name}
														</p>
														<p>
															<strong>电话: </strong>
															{order.saleUser ? order.saleUser.phone : order.createdUser.phone}
														</p>
														<p>
															<strong>邮箱: </strong>
															<a href={`mailto:${order.saleUser ? order.saleUser.email : order.createdUser.email}`}>
																{order.saleUser ? order.saleUser.email : order.createdUser.email}
															</a>
														</p>
													</ListGroup.Item>
													<ListGroup.Item>
														<p>
															<strong>销售助理: </strong>
															{order.assistantUser ? order.assistantUser.name : order.createdUser.name}
														</p>
														<p>
															<strong>电话: </strong>
															{order.assistantUser ? order.assistantUser.phone : order.createdUser.phone}
														</p>
														<p>
															<strong>邮箱: </strong>
															<a href={`mailto:${order.assistantUser ? order.assistantUser.email : order.createdUser.email}`}>
																{order.assistantUser ? order.assistantUser.email : order.createdUser.email}
															</a>
														</p>
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>货款支付</strong>
										</h5>
										<p>
											<strong>方式: </strong>
											{order.paymentMethod}
										</p>

										<Row className='mb-2'>
											<Col>产品总价</Col>
											<Col>¥ {order.itemsPrice}</Col>
										</Row>
										<Row className='mb-2'>
											<Col>其他费用</Col>
											<Col>¥ {order.deliveryPrice}</Col>
										</Row>
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
											<>
												<Row className='mb-2'>
													<Col>预付百分比:</Col>
													<Col>
														<strong>{order.prePaymentPercent} %</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.percentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
											<>
												<Row className='mb-2'>
													<Col>预支付:</Col>
													<Col>
														<strong>¥ {order.prePayment}</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.prePaymentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
										<Row className='mb-3'>
											<Col>
												<strong>总价</strong>
											</Col>
											<Col>
												<strong>¥ {order.totalPrice}</strong>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>订单列表</strong>
										</h5>
										<ListGroup.Item className='bg-primary'>
											<Row className='text-white'>
												<Col>
													<strong>货号</strong>
												</Col>
												<Col>
													<strong>设备名称</strong>
												</Col>
												<Col>
													<strong>数量</strong>
												</Col>
												<Col>
													<strong>单价</strong>
												</Col>
												<Col>
													<strong>小计</strong>
												</Col>
											</Row>
										</ListGroup.Item>
										{order.orderItems.map((item, index) => (
											<>
												<ListGroup.Item key={index}>
													<Row>
														<Col>{item.productCode}</Col>
														<Col>{item.name}</Col>
														<Col>{item.qty}</Col>
														<Col>¥{item.sellingPrice}</Col>
														<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
													</Row>
												</ListGroup.Item>
											</>
										))}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Modal show={show}>
				<Modal.Header>
					<Modal.Title>恭喜您 🎉 </Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<div className='text-center'>
						<div className='wizard-lottie-wrapper'>
							<div className='wizard-lottie mx-auto'>
								<Lottie animationData={celebration} />
							</div>
						</div>
						<h4 className='mb-1'>订单创建成功!</h4>
						<p className='fs-0'>已通知管理员</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleClose}>
						好!
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default OrderCreateSuccessScreen;
