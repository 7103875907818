import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { MaterialReactTable, getMRT_RowSelectionHandler, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { useGetProductsQuery } from '../../../../slices/productsApiSlice';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addToOrder } from '../../../../slices/orderSlice';

const SelectOrderProductModal = ({ modalShow, setModalShow }) => {
	const dispatch = useDispatch();
	const [rowSelection, setRowSelection] = useState({});
	const order = useSelector((state) => state.order);
	const { orderItems } = order;
	const productIds = orderItems.map((item) => item._id);

	const columns = useMemo(
		() => [
			{
				header: '货号',
				accessorKey: 'productCode',
				enableGrouping: false,
			},
			{
				header: '产品名称',
				accessorKey: 'name',
			},
			{
				header: '厂家',
				accessorKey: 'supplierName',
			},
			{
				header: '品牌',
				accessorKey: 'brand',
			},
			{
				header: '库存',
				accessorKey: 'countInStock',
			},
			{
				header: '单价',
				accessorKey: 'minimumSellingPrice',
			},
		],
		[]
	);

	const { data: fetchedProducts = [], isError: isLoadingProductsError, isLoading: isLoadingProducts, isFetching: isFetchingProducts } = useGetProductsQuery();

	const table = useMaterialReactTable({
		columns,
		data: fetchedProducts,
		displayColumnDefOptions: {
			'mrt-row-expand': {
				enableResizing: true,
			},
		},
		getRowId: (row) => {
			return row._id;
		},
		enableColumnResizing: true,
		enableGrouping: true,
		enableStickyHeader: true,
		enableStickyFooter: true,
		enableBatchRowSelection: true,
		enableSubRowSelection: true,
		enableFullScreenToggle: false,
		enableRowSelection: (row) => {
			return !productIds.includes(row.original._id);
		}, //enable row selection conditionally per row
		localization: MRT_Localization_ZH_HANS,
		onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
		initialState: {
			density: 'compact',
			pagination: { pageIndex: 0, pageSize: 10 },
			sorting: [{ id: 'supplierName', desc: true }], //sort by state by default
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerChipProps: { color: 'primary' },
		muiTableContainerProps: { sx: { maxHeight: 700 } },
		//clicking anywhere on the row will select it
		muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
			onClick: (event) => getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
			sx: { cursor: 'pointer' },
		}),
		//optionally, use single-click to activate editing mode instead of default double-click
		muiTableBodyCellProps: ({ cell, column, table }) => ({
			onClick: () => {
				table.setEditingCell(cell); //set editing cell
				//optionally, focus the text field
				queueMicrotask(() => {
					const textField = table.refs.editInputRefs.current[column.id];
					if (textField) {
						textField.focus();
						textField.select?.();
					}
				});
			},
			muitablepaperprops: {
				elevation: 0, //change the mui box shadow
				//customize paper styles
				sx: {
					borderRadius: '0',
					border: '0px',
				},
			},
		}),
		state: {
			isLoading: isLoadingProducts,
			//isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
			showAlertBanner: isLoadingProductsError,
			showProgressBars: isFetchingProducts,
			rowSelection,
		},
		positionToolbarAlertBanner: 'bottom', //show selected rows count on bottom toolbar
		//add custom action buttons to top-left of top toolbar
		renderTopToolbarCustomActions: ({ table }) => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				<Button
					color='secondary'
					size='sm'
					onClick={() => {
						// table.getSelectedRowModel().rows; //or read entire rows
						const rows = table.getSelectedRowModel().rows;

						const originals = rows.map((row) => {
							const original = { ...row.original, qty: 1, sellingPrice: row.original.minimumSellingPrice };
							return original;
						});
						if (originals.length !== 0) {
							dispatch(addToOrder(originals));
							setRowSelection([]);
							setModalShow(false);
							toast.success('产品添加成功');
						} else {
							toast.error('没有选择产品');
						}
					}}
					variant='primary'
				>
					添加至订单
				</Button>
			</Box>
		),
	});

	return (
		<Modal show={modalShow} fullscreen={true} onHide={() => setModalShow(false)} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>选择产品信息</Modal.Title>
			</Modal.Header>
			<Modal.Body className='p-0'>
				<MaterialReactTable table={table} />
			</Modal.Body>
		</Modal>
	);
};

SelectOrderProductModal.propTypes = {
	modalShow: PropTypes.bool.isRequired,
	setModalShow: PropTypes.func.isRequired,
};

export default SelectOrderProductModal;
