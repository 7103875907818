import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { useGetMyNewNotificationQuery } from '../slices/notificationsApiSlice';

export const NotificationContext = createContext({ products: [] });

const NotificationProvider = ({ children }) => {
	const { data: newUnreadNotifications = [], isLoading: loadingNotify, refetch } = useGetMyNewNotificationQuery();

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<NotificationContext.Provider
			value={{
				newUnreadNotifications,
				loadingNotify,
				refetch,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

NotificationProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationProvider;
