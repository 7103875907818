import { APPROVALS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const approvalsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		initiateQuotaApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/quota`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		initiateCustomerCreateApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/customer`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		initiateOrderApproval: builder.mutation({
			query: (approval) => ({
				url: `${APPROVALS_URL}/order`,
				method: 'POST',
				body: { ...approval },
			}),
			invalidatesTags: ['Approval'],
		}),
		getMyApprovals: builder.query({
			query: () => ({
				url: `${APPROVALS_URL}/mine`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		getApprovalById: builder.query({
			query: (approvalId) => ({
				url: `${APPROVALS_URL}/${approvalId}`,
			}),
			providesTags: ['Approval'],
			keepUnusedDataFor: 5,
		}),
		updateApprovalById: builder.mutation({
			query: (data) => ({
				url: `${APPROVALS_URL}/${data._id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Approval'],
		}),
		deleteApproval: builder.mutation({
			query: (approvalId) => ({
				url: `${APPROVALS_URL}/${approvalId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Approval'],
		}),
	}),
});

export const {
	useInitiateQuotaApprovalMutation,
	useInitiateCustomerCreateApprovalMutation,
	useGetMyApprovalsQuery,
	useGetApprovalByIdQuery,
	useUpdateApprovalByIdMutation,
	useDeleteApprovalMutation,
} = approvalsApiSlice;
