import PropTypes from 'prop-types';
import { Card, Col, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../../../components/Message';
import CustomerQuotaApplyModal from './modal/CustomerQuotaApplyModal';

import { useState } from 'react';
import Flex from '../../../components/common/Flex';
import IconButton from '../../../components/common/IconButton';
import { clearScheduleNodes } from '../../../slices/orderSlice';
import AddPaymentScheduleModal from './modal/AddPaymentScheduleModal';
import EditPaymentScheduleModal from './modal/EditPaymentScheduleModal';

const OrderPaymentInfo = ({ quota, balance }) => {
	const order = useSelector((state) => state.order);
	const {
		setValue,
		register,
		watch,
		formState: { errors },
	} = useFormContext();
	const dispatch = useDispatch();
	const { customer, totalPrice, scheduleNodes } = order;
	const { prePaymentOption, paymentMethod, prePaymentPercent, percentBalance, percentPayment } = watch();

	const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
	const [isOpenEditScheduleModal, setIsOpenEditScheduleModal] = useState(false);
	return (
		<>
			{paymentMethod === '全额支付' && <Message variant='success'>全额支付: ¥ {order.totalPrice} </Message>}
			{paymentMethod === '部分支付' && (
				<>
					<p>预付信息</p>
					<Form.Check
						inline
						type='radio'
						id='defaultRadio'
						label='百分比'
						name='prePaymentRadio'
						value='百分比'
						{...register('prePaymentOption', {
							onChange: (e) => {
								setValue('prePaymentOption', e.target.value);
							},
						})}
					/>
					<Form.Check
						inline
						type='radio'
						id='checkedRadio'
						label='预付款'
						name='prePaymentRadio'
						value='预付款'
						{...register('prePaymentOption', { onChange: (e) => setValue('prePaymentOption', e.target.value) })}
					/>
					{prePaymentOption === '百分比' && (
						<>
							<Form.Group as={Row} className='g-1 mb-3' controlId='prePaymentPercent'>
								<Form.Label column sm='2'>
									百分比<span className='text-danger'>*</span>
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control
											type='number'
											name='prePaymentPercent'
											autoComplete='false'
											isInvalid={!!errors.prePaymentPercent}
											{...register('prePaymentPercent', {
												valueAsNumber: true,
												onChange: (e) => {
													dispatch(clearScheduleNodes());
													const calculatedPercentBalance = (totalPrice - totalPrice * (e.target.value / 100)).toFixed(2);
													const calculatedPercentPayment = (totalPrice * (e.target.value / 100)).toFixed(2);
													setValue('prePaymentPercent', Number(e.target.value));
													setValue('percentPayment', calculatedPercentPayment >= 0 ? calculatedPercentPayment : 0);
													setValue('percentBalance', calculatedPercentBalance >= 0 ? calculatedPercentBalance : totalPrice);
												},
											})}
										/>
										<InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
										<Form.Control.Feedback type='invalid'>{errors.prePaymentPercent?.message}</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='g-1 align-items-center mb-3'>
								<Form.Label column sm='2'>
									首次支付
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control disabled type='text' name='percentPayment' {...register('percentPayment', { valueAsNumber: true })} />
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='g-1 align-items-center mb-3'>
								<Form.Label column sm='2'>
									尾款剩余
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control disabled type='text' name='percentBalance' {...register('percentBalance', { valueAsNumber: true })} />
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							{scheduleNodes.length === 0 ? (
								<IconButton
									variant='primary'
									iconClassName='me-2'
									icon='plus'
									// transform="shrink-3"
									size='sm'
									className='mt-1 mb-3 w-75'
									disabled={prePaymentPercent < 0 || prePaymentPercent > 99}
									onClick={() => {
										setIsOpenScheduleModal(!isOpenScheduleModal);
									}}
								>
									制定尾款计划
								</IconButton>
							) : (
								<>
									<Flex justifyContent='between'>
										<strong>尾款支付节点</strong>
										<IconButton
											variant='primary'
											iconClassName='me-2'
											icon='edit'
											// transform="shrink-3"
											size='sm'
											className='mt-1 mb-3'
											onClick={() => {
												setIsOpenEditScheduleModal(!isOpenEditScheduleModal);
											}}
										>
											编辑
										</IconButton>
									</Flex>

									<ul className='timeline-order'>
										{scheduleNodes.length !== 0 &&
											scheduleNodes.map((node) => (
												<li className='timeline-item mb-3'>
													<Card>
														<Card.Header className='bg-body-tertiary py-1'>
															<Card.Title>
																<h6>{node.title}</h6>
															</Card.Title>
														</Card.Header>
														<Card.Body className='pt-3 pb-3'>
															<Row>
																<Col sm={6}>账期</Col>
																<Col sm={6}>{node.paymentDays}天</Col>
																<Col sm={6}>付款百分比</Col>
																<Col sm={6}>{node.paymentPercent}%</Col>
																<Col sm={6}>付款金额</Col>
																<Col sm={6}>{node.paymentAmount} 元</Col>
															</Row>
														</Card.Body>
													</Card>
												</li>
											))}
									</ul>
								</>
							)}
						</>
					)}
					{prePaymentOption === '预付款' && (
						<>
							<Form.Group as={Row} className='g-1 mb-3' controlId='prePayment'>
								<Form.Label column sm='2'>
									预付款<span className='text-danger'>*</span>
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control
											type='number'
											name='prePayment'
											placeholder='请填写预付款项'
											autoComplete='false'
											isInvalid={!!errors.prePayment}
											{...register('prePayment', {
												valueAsNumber: true,
												onChange: (e) => {
													const calculatedPrePaymentBalance = (totalPrice - e.target.value).toFixed(2);
													setValue('prePaymentBalance', calculatedPrePaymentBalance >= 0 ? calculatedPrePaymentBalance : 0);
												},
											})}
										/>
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
										<Form.Control.Feedback type='invalid'>{errors.prePayment?.message}</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='g-1 align-items-center mb-3'>
								<Form.Group as={Row} className='g-1 align-items-center mb-3'>
									<Form.Label column sm='2'>
										尾款剩余
									</Form.Label>
									<Col sm='6'>
										<InputGroup>
											<Form.Control disabled type='text' name='prePaymentBalance' {...register('prePaymentBalance')} />
											<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
										</InputGroup>
									</Col>
								</Form.Group>
							</Form.Group>
						</>
					)}

					<Form.Group className='mb-3' controlId='paymentExplain'>
						<Form.Label>付款说明</Form.Label>
						<FloatingLabel label='在此描述付款协商内容......'>
							<Form.Control as='textarea' name='paymentExplain' rows={3} style={{ height: '100px' }} isInvalid={!!errors.paymentExplain} {...register('paymentExplain')} />
							<Form.Control.Feedback type='invalid'>{errors.paymentExplain?.message}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</>
			)}
			{paymentMethod === '额度支付' && quota === 0 && (
				<Message variant='warning'>
					<p>
						客户: {customer.name}
						<strong>无可用额度</strong>
					</p>
					<p>
						<CustomerQuotaApplyModal />
					</p>
				</Message>
			)}
			{paymentMethod === '额度支付' && quota !== 0 && balance < totalPrice && (
				<>
					<Message variant='success'>
						当前选择的客户 {order.customer.name} 总额度为 ¥ {quota} /可用额度为 ¥ <strong>{balance}</strong>
					</Message>
					<Message variant='danger'>
						<p>当前订单需支付: {totalPrice} 元</p>
						<strong>
							可用余额不足! <Link>去提额</Link>
						</strong>
					</Message>
				</>
			)}
			{paymentMethod === '额度支付' && quota !== 0 && balance > totalPrice && (
				<>
					<Message variant='success'>
						当前选择的客户 {order.customer.name} 总额度为 ¥ {quota} /可用额度为 ¥ <strong>{balance}</strong>
					</Message>
					<p>当前订单需支付: {totalPrice} 元</p>
				</>
			)}
			<AddPaymentScheduleModal
				isOpenScheduleModal={isOpenScheduleModal}
				setIsOpenScheduleModal={setIsOpenScheduleModal}
				register={register}
				setValue={setValue}
				prePaymentPercent={prePaymentPercent}
				percentBalance={percentBalance}
				percentPayment={percentPayment}
			/>
			<EditPaymentScheduleModal
				isOpenEditScheduleModal={isOpenEditScheduleModal}
				setIsOpenEditScheduleModal={setIsOpenEditScheduleModal}
				register={register}
				setValue={setValue}
				prePaymentPercent={prePaymentPercent}
				percentBalance={percentBalance}
				percentPayment={percentPayment}
			/>
		</>
	);
};

OrderPaymentInfo.propTypes = {
	paymentMethod: PropTypes.string,
};

export default OrderPaymentInfo;
