import { NOTIFICATIONS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const notificationsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		addNewNotificationToUser: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/add/to_user`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		addNewNotificationFromUser: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/add/from_user`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		getMyNewNotification: builder.query({
			query: () => ({
				url: `${NOTIFICATIONS_URL}/new`,
			}),
			providesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
		setNewNotificationToRead: builder.mutation({
			query: (notifications) => ({
				url: `${NOTIFICATIONS_URL}/set/read`,
				method: 'PUT',
				body: notifications,
			}),
			invalidatesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
	}),
});

export const { useGetMyNewNotificationQuery, useSetNewNotificationToReadMutation, useAddNewNotificationToUserMutation, useAddNewNotificationFromUserMutation } =
	notificationsApiSlice;
