import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Card, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import CardDropdown from '../../components/common/CardDropdown';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import useAdvanceTable from '../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../providers/AdvanceTableProvider';
import {
	useGetPendingOrderForAssistantQuery,
	useGetPendingOrderForBuyerQuery,
	useGetPendingOrderForFinanceQuery,
	useGetPendingOrderForManagerQuery,
	useGetPendingOrderForSaleQuery,
} from '../../slices/dashboardApiSlice';
import RecentPurchasesHeader from './RecentPurchasesHeader';
import moment from 'moment';
const RecentPurchases = () => {
	const { userInfo } = useSelector((state) => state.auth);
	const columns = useMemo(() => {
		const userColumns = [
			{
				accessorKey: 'orderId',
				header: '订单编号',
				meta: {
					headerProps: { className: 'pe-1 sticky-column text-900' },
					cellProps: {
						className: 'fw-semibold',
					},
				},
				cell: ({ row: { original } }) => {
					const { orderId, _id } = original;
					return <Link to={`/order/detail/${_id}`}>{orderId}</Link>;
				},
			},
			{
				accessorKey: 'currentAccountsReceivable',
				header: '应收账款',
				meta: {
					headerProps: { className: 'pe-3 text-900' },
				},
				cell: ({ row: { original } }) => {
					const { currentAccountsReceivable } = original;
					return (
						<Badge pill bg='danger' className='me-2'>
							<FontAwesomeIcon icon='fa-yen-sign' className='me-2' />
							<span className='fs-11'>{currentAccountsReceivable}元</span>
						</Badge>
					);
				},
			},
			{
				accessorKey: 'paymentStatus',
				header: '支付状态',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { paymentStatus } = original;

					let bg = '';
					let icon = '';
					let text = '';
					switch (paymentStatus) {
						case 'waitingPayPlaceOrder':
							bg = 'warning';
							icon = 'stream';
							text = '待收预付款';
							break;
						case 'waitingPayDelivery':
							bg = 'warning';
							icon = 'stream';
							text = '待收发货尾款';
							break;
						case 'waitingPayAccept':
							bg = 'warning';
							icon = 'stream';
							text = '待收验收尾款';
							break;
						case 'waitingPayComplete':
							bg = 'warning';
							icon = 'stream';
							text = '待支付完成';
							break;
						case 'payComplete':
							bg = 'success';
							icon = 'check';
							text = '支付完成';
							break;
						default:
							break;
					}
					return (
						<Badge pill bg={bg} className='me-2'>
							<FontAwesomeIcon icon={icon} className='me-2' />
							<span className='fs-11'>{text}</span>
						</Badge>
					);
				},
			},
			{
				accessorKey: 'deliveryStatus',
				header: '物流状态',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { deliveryStatus } = original;
					let bg = '';
					let icon = '';
					let text = '';
					switch (deliveryStatus) {
						case 'waitingDelivery':
							bg = 'danger';
							icon = 'stream';
							text = '待发货';
							break;
						case 'waitingAccept':
							bg = 'danger';
							icon = 'stream';
							text = '待验收';
							break;
						case 'waitingComplete':
							bg = 'warning';
							icon = 'stream';
							text = '待完成';
							break;
						case 'deliveryComplete':
							bg = 'success';
							icon = 'check';
							text = '物流完成';
							break;
						default:
							break;
					}
					return (
						<Badge pill bg={bg} className='me-2'>
							<FontAwesomeIcon icon={icon} className='me-2' />
							<span className='fs-11'>{text}</span>
						</Badge>
					);
				},
			},
			{
				accessorKey: 'totalPrice',
				header: '总价',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>¥{original.totalPrice}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/order/detail/${original?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		];
		if (userInfo.role === 'manager') {
			const b = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
			];
			userColumns.splice(1, 1, ...b);
		}
		if (['sale', 'user'].includes(userInfo.role)) {
			const x = [
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask, _id } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>
									<Link to={`/order/detail/${_id}`} className='text-white'>
										{orderTask}
									</Link>
								</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...x);
		}
		if (userInfo.role === 'assistant') {
			const a = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>{orderTask}</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...a);
		}
		if (userInfo.role === 'buyer') {
			const buyerColumns = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>{orderTask}</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...buyerColumns);
			return userColumns.filter((column) => column.accessorKey !== 'currentAccountsReceivable');
		}
		return userColumns;
	}, [userInfo.role]);

	const [skipLoadManager] = useState(true);
	const [skipLoadFinance] = useState(true);
	const [skipLoadBuyer] = useState(true);
	const [skipLoadSale] = useState(true);
	const [skipLoadAssistant] = useState(true);

	const {
		data: orderPendingForManager = [],
		isLoading: loadingManagerPending,
		error: loadingManagerPendingError,
		isUninitialized: uninitializedManager,
		refetch: refetchManagerPending,
	} = useGetPendingOrderForManagerQuery(undefined, {
		skip: userInfo.role === 'manager' ? !skipLoadManager : skipLoadManager,
	});

	const {
		data: orderPendingForFinance = [],
		isLoading: loadingFinancePending,
		error: loadingFinancePendingError,
		isUninitialized: uninitializedFinance,
		refetch: refetchFinancePending,
	} = useGetPendingOrderForFinanceQuery(undefined, {
		skip: userInfo.role === 'finance' ? !skipLoadFinance : skipLoadFinance,
	});
	const {
		data: orderPendingForBuyer = [],
		isLoading: loadingBuyerPending,
		error: loadingBuyerPendingError,
		isUninitialized: uninitializedBuyer,
		refetch: refetchBuyerPending,
	} = useGetPendingOrderForBuyerQuery(undefined, {
		skip: userInfo.role === 'buyer' ? !skipLoadBuyer : skipLoadBuyer,
	});
	const {
		data: orderPendingForSale = [],
		isLoading: loadingSalePending,
		error: loadingSalePendingError,
		isUninitialized: uninitializedSale,
		refetch: refetchSalePending,
	} = useGetPendingOrderForSaleQuery(undefined, {
		skip: ['sale', 'user'].includes(userInfo.role) ? !skipLoadSale : skipLoadSale,
	});
	const {
		data: orderPendingForAssistant = [],
		isLoading: loadingAssistantPending,
		error: loadingAssistantPendingError,
		isUninitialized: uninitializedAssistant,
		refetch: refetchAssistantPending,
	} = useGetPendingOrderForAssistantQuery(undefined, {
		skip: userInfo.role === 'assistant' ? !skipLoadAssistant : skipLoadAssistant,
	});

	useEffect(() => {
		if (!uninitializedManager) {
			refetchManagerPending();
		}
		if (!uninitializedSale) {
			refetchSalePending();
		}
		if (!uninitializedAssistant) {
			refetchAssistantPending();
		}
		if (!uninitializedFinance) {
			refetchFinancePending();
		}
		if (!uninitializedBuyer) {
			refetchBuyerPending();
		}
	}, [
		refetchAssistantPending,
		refetchBuyerPending,
		refetchFinancePending,
		refetchManagerPending,
		refetchSalePending,
		skipLoadAssistant,
		skipLoadBuyer,
		skipLoadFinance,
		skipLoadSale,
		uninitializedAssistant,
		uninitializedBuyer,
		uninitializedFinance,
		uninitializedManager,
		uninitializedSale,
		userInfo.role,
	]);
	const table = useAdvanceTable({
		data:
			userInfo.role === 'buyer'
				? orderPendingForBuyer
				: ['sale', 'user'].includes(userInfo.role)
				? orderPendingForSale
				: userInfo.role === 'assistant'
				? orderPendingForAssistant
				: userInfo.role === 'manager'
				? orderPendingForManager
				: orderPendingForFinance,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<AdvanceTableProvider {...table}>
			<Card className='mb-3 h-40'>
				<Card.Header>
					<RecentPurchasesHeader />
				</Card.Header>
				<Card.Body className='p-0'>
					<AdvanceTable
						headerClassName='bg-200 text-nowrap align-middle'
						rowClassName='btn-reveal-trigger text-nowrap align-middle'
						tableProps={{
							size: 'sm',
							className: 'fs-10 mb-0 overflow-hidden',
						}}
					/>
					{!uninitializedManager && !loadingManagerPending && orderPendingForManager.length === 0 && (
						<div className='my-5 w-100 text-center'>
							<p className=''>暂无订单</p>
						</div>
					)}
					{!uninitializedFinance && !loadingFinancePending && orderPendingForFinance.length === 0 && (
						<div className='my-5 w-100 text-center'>
							<p className=''>暂无待处理订单</p>
						</div>
					)}
					{!uninitializedBuyer && !loadingBuyerPending && orderPendingForBuyer.length === 0 && (
						<div className='my-5 w-100 text-center'>
							<p className=''>暂无待处理订单</p>
						</div>
					)}
					{!uninitializedSale && !loadingSalePending && orderPendingForSale.length === 0 && (
						<div className='my-5 w-100 text-center'>
							<p className=''>暂无待处理订单</p>
						</div>
					)}
					{!uninitializedAssistant && !loadingAssistantPending && orderPendingForAssistant.length === 0 && (
						<div className='my-5 w-100 text-center'>
							<p className=''>暂无待处理订单</p>
						</div>
					)}
					{loadingManagerPending && <Loader />}
					{loadingFinancePending && <Loader />}
					{loadingBuyerPending && <Loader />}
					{loadingSalePending && <Loader />}
					{loadingAssistantPending && <Loader />}
					{loadingManagerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
					{loadingFinancePendingError && <Message animation='border' variant='primary' className='mt-3' />}
					{loadingBuyerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
					{loadingSalePendingError && <Message animation='border' variant='primary' className='mt-3' />}
					{loadingAssistantPendingError && <Message animation='border' variant='primary' className='mt-3' />}
				</Card.Body>
				<Card.Footer>
					<AdvanceTableFooter rowInfo navButtons />
				</Card.Footer>
			</Card>
		</AdvanceTableProvider>
	);
};

export default RecentPurchases;
