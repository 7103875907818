/* eslint-disable react/jsx-pascal-case */
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
//Import Material React Table Translations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { Button, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeFromOrder, updateOrderItemsQty, updateOrderItemsSellingPrice } from '../../../../slices/orderSlice';

const OrderProductListTable = () => {
	const dispatch = useDispatch();
	const [validationErrors, setValidationErrors] = useState({});
	const [rowSelection] = useState({});
	const order = useSelector((state) => state.order);
	const { orderItems } = order;

	const handleRemoveOrderItem = (id) => {
		dispatch(removeFromOrder(id));
		toast.warning('产品已移除');
	};

	const sumQty = useMemo(() => orderItems.reduce((acc, curr) => acc + curr.qty, 0), [orderItems]);

	const sumPrice = useMemo(() => orderItems.reduce((acc, curr) => acc + curr.sellingPrice * curr.qty, 0), [orderItems]);

	const columns = useMemo(
		() => [
			{
				header: '货号',
				accessorKey: 'productCode',
				enableEditing: false,
				size: 120,
			},
			{
				header: '品名',
				accessorKey: 'name',
				enableEditing: false,
			},
			{
				header: '品牌',
				accessorKey: 'brand',
				enableEditing: false,
			},
			{
				header: '规格',
				accessorKey: 'specification',
				enableEditing: false,
			},
			{
				header: '厂家',
				accessorKey: 'supplierName',
				enableEditing: false,
			},
			{
				header: '底价',
				accessorKey: 'minimumSellingPrice',
				enableEditing: false,
			},
			{
				header: '数量',
				accessorKey: 'qty',
				muiEditTextFieldProps: ({ cell, row }) => ({
					type: 'number',
					required: true,
					error: !!validationErrors?.[cell.id],
					helperText: validationErrors?.[cell.id],
					onBlur: (event) => {
						console.log(validationErrors?.[cell.id]);
						if (validationErrors?.[cell.id] === undefined) {
							dispatch(updateOrderItemsQty({ ...row.original, qty: Number(event.currentTarget.value) }));
						} else {
							dispatch(updateOrderItemsQty({ ...row.original, qty: row.original.qty }));
						}
					},
					onChange: (event) => {
						const validationError = !validateQty(Number(event.currentTarget.value)) ? '数量不能小于1' : undefined;
						setValidationErrors({
							...validationErrors,
							[cell.id]: validationError,
						});
					},
				}),
				Footer: () => (
					<Stack>
						总数量:
						<Box color='warning.main'>{Math.round(sumQty)}</Box>
					</Stack>
				),
			},
			{
				header: '销售单价',
				accessorKey: 'sellingPrice',
				muiEditTextFieldProps: ({ cell, row, colunms }) => ({
					type: 'number',
					required: true,
					error: !!validationErrors?.[cell.id],
					helperText: validationErrors?.[cell.id],
					onBlur: (event) => {
						console.log(validationErrors?.[cell.id]);
						if (validationErrors?.[cell.id] === undefined) {
							// 销售单价无需审批，此产品无需审批
							dispatch(updateOrderItemsSellingPrice({ ...row.original, sellingPrice: Number(event.currentTarget.value) }));
						} else {
							// 销售单价低于底价，此产品需要审批
							dispatch(updateOrderItemsSellingPrice({ ...row.original, sellingPrice: row.original.sellingPrice }));
							setValidationErrors({});
						}
					},
					onChange: (event) => {
						const validationError = !validateSellingPrice(Number(event.currentTarget.value), row.original.minimumSellingPrice) ? '销售价格不可低于底价' : undefined;
						setValidationErrors({
							...validationErrors,
							[cell.id]: validationError,
						});
					},
				}),
				Footer: () => (
					<Stack>
						总价:
						<Box color='warning.main'>¥ {sumPrice.toFixed(2)}</Box>
					</Stack>
				),
			},
		],
		[dispatch, sumPrice, sumQty, validationErrors]
	);

	const table = useMaterialReactTable({
		columns,
		data: orderItems,
		displayColumnDefOptions: {
			'mrt-row-expand': {
				enableResizing: true,
			},
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		enableColumnResizing: true,
		enableRowActions: true,
		positionActionsColumn: 'first',
		enableRowNumbers: true,
		enableStickyHeader: true,
		enableStickyFooter: true,
		getRowId: (row) => row._id,
		enableResizing: true,
		enableMultiRowSelection: false,
		enableGrouping: true,
		enableFullScreenToggle: false,
		enableCellActions: true,
		enableClickToCopy: 'context-menu',
		enableEditing: true,
		enableColumnPinning: true,
		editDisplayMode: 'cell',
		localization: MRT_Localization_ZH_HANS,
		initialState: {
			showGlobalFilter: true,
			density: 'compact',
			expanded: true, //expand all groups by default
			// grouping: ['supplier.name'],
			columnPinning: {
				left: ['mrt-row-actions', 'mrt-row-numbers', 'productCode', 'name'],
				// right: ['mrt-row-actions'],
			},
			pagination: { pageIndex: 0, pageSize: 10 },
			sorting: [{ id: 'supplierName', desc: false }], //sort by state by default
		},

		muiToolbarAlertBannerChipProps: { color: 'primary' },
		muiTableContainerProps: { sx: { maxHeight: 900, minHeight: 348 } },
		state: {
			rowSelection,
		},
		positionToolbarAlertBanner: 'top',
		renderRowActions: ({ row, table }) => (
			<Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
				<Button
					size='sm'
					variant='outline-danger'
					className='border-none trasparent'
					onClick={() => {
						handleRemoveOrderItem(row.id);
					}}
				>
					<FontAwesomeIcon icon='fa-solid fa-trash' />
				</Button>
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default OrderProductListTable;

const validateQty = (qty) => {
	if (qty >= 1) {
		return true;
	} else {
		return false;
	}
};
const validateSellingPrice = (sellingPrice, minimumSellingPrice) => {
	if (sellingPrice >= minimumSellingPrice) {
		return true;
	} else {
		return false;
	}
};
