import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import SubtleBadge from '../../components/common/SubtleBadge';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';
import useAdvanceTable from '../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../providers/AdvanceTableProvider';
import { useGetMyApprovalsQuery } from '../../slices/approvalsApiSlice';

const columns = [
	{
		accessorKey: 'type',
		header: '审批类型',
		meta: {
			headerProps: { className: 'pe-1 sticky-column text-900' },
			cellProps: {
				className: 'fw-semibold',
			},
		},
		cell: ({ row: { original } }) => {
			const { type } = original;
			return (
				<>
					{type === 'customer' && <span>客户添加申请</span>}
					{type === 'quota' && <span>客户额度申请</span>}
					{type === 'order' && <span>订单申请</span>}
				</>
			);
		},
	},
	{
		accessorKey: 'title',
		header: '审批事项',
		meta: {
			headerProps: { className: 'pe-7 text-900' },
		},
		cell: ({ row: { original } }) => {
			const { _id, title } = original;
			return <Link to={`/approval/detail/${_id}`}>{title}</Link>;
		},
	},
	{
		accessorKey: 'status',
		header: '审批状态',
		meta: {
			headerProps: { className: 'text-center text-900' },
			cellProps: { className: 'fs-9' },
		},
		cell: ({ row: { original } }) => {
			const { status } = original;
			return (
				<SubtleBadge pill bg={status.type} className='d-block'>
					{status.content}
					<FontAwesomeIcon icon={status.icon} transform='shrink-2' className='ms-1' />
				</SubtleBadge>
			);
		},
	},
	{
		accessorKey: 'applicant.name',
		header: '销售员',
	},
	{
		accessorKey: 'assistant.name',
		header: '销售助理',
	},
];
const ApprovalListScreen = () => {
	const { data: myApprovals, isLoading, refetch } = useGetMyApprovalsQuery();
	const table = useAdvanceTable({
		data: myApprovals ? myApprovals : [],
		columns,
		sortable: true,
		pagination: true,
		perPage: 20,
	});
	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<AdvanceTableProvider {...table}>
			<Card className='mb-3'>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									审批列表
								</li>
							</ol>
						</nav>
					</Card.Title>
				</Card.Header>
				<Card.Body className='p-0'>
					{isLoading ? (
						<Loader />
					) : (
						<AdvanceTable
							headerClassName='bg-200 text-nowrap align-middle'
							rowClassName='btn-reveal-trigger text-nowrap align-middle'
							tableProps={{
								size: 'sm',
								className: 'fs-10 mb-0 overflow-hidden',
							}}
						/>
					)}
				</Card.Body>
				<Card.Footer>
					<AdvanceTableFooter rowInfo navButtons />
				</Card.Footer>
			</Card>
		</AdvanceTableProvider>
	);
};

export default ApprovalListScreen;
