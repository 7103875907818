import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotificationListScreen = () => {
	return (
		<div>
			<Button as={Link} to='/'>
				返回
			</Button>
		</div>
	);
};

export default NotificationListScreen;
