import React from 'react';
import { Button, CloseButton, Modal, Spinner } from 'react-bootstrap';

const SystemAlert = ({ title, bodyText, cancelText, confirmText, confirmWaiting = false, confirmColor = 'primary', modalShow, setModalShow, ...rest }) => {
	const { handleConfirm } = rest;
	return (
		<Modal show={modalShow} onHide={() => setModalShow(false)} backdrop='static' keyboard={false}>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
				<CloseButton onClick={() => setModalShow(false)} />
			</Modal.Header>
			<Modal.Body>{bodyText}</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => setModalShow(false)}>
					{cancelText}
				</Button>
				<Button variant={confirmColor} onClick={handleConfirm}>
					{confirmWaiting ? <Spinner size='sm' /> : confirmText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default SystemAlert;
