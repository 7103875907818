import { apiSlice } from './apiSlice';
import { ORDERS_URL, PAYPAL_URL } from '../constants';

export const ordersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createOrder: builder.mutation({
			query: (order) => ({
				url: ORDERS_URL,
				method: 'POST',
				body: { ...order },
			}),
		}),
		getOrderDetails: builder.query({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}`,
			}),
			keepUnusedDataFor: 5,
		}),
		payOrders: builder.mutation({
			query: ({ orderId, details }) => ({
				url: `${ORDERS_URL}/${orderId}/pay`,
				method: 'PUT',
				body: { ...details },
			}),
		}),
		getPayPalClientId: builder.query({
			query: () => ({
				url: PAYPAL_URL,
			}),
			keepUnusedDataFor: 5,
		}),
		getMyOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getAssistantOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine/assistant`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getSaleOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine/sale`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getManagerOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine/manager`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getFinanceOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine/finance`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getBuyerOrders: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/mine/buyer`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getNewIndexOfToday: builder.query({
			query: () => ({
				url: `${ORDERS_URL}/index`,
			}),
			keepUnusedDataFor: 5,
		}),
		getNewIndexOfTodayBySelectedSale: builder.mutation({
			query: (sale) => ({
				url: `${ORDERS_URL}/index/sale/${sale._id}`,
			}),
			keepUnusedDataFor: 5,
		}),
		getOrders: builder.query({
			query: () => ({
				url: ORDERS_URL,
			}),
			keepUnusedDataFor: 5,
		}),
		addAccountsReceivable: builder.mutation({
			query: ({ orderId, details }) => ({
				url: `${ORDERS_URL}/${orderId}/ar/add`,
				method: 'PUT',
				body: { ...details },
			}),
		}),
		deliverOrder: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/deliver`,
				method: 'PUT',
			}),
		}),
		deliverPayOrders: builder.mutation({
			query: ({ orderId, details }) => ({
				url: `${ORDERS_URL}/${orderId}/deliverpay`,
				method: 'PUT',
				body: { ...details },
			}),
		}),
		acceptOrder: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/accept`,
				method: 'PUT',
			}),
		}),
		completeOrder: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/complete`,
				method: 'PUT',
			}),
		}),
		checkOrderId: builder.mutation({
			query: (orderId) => ({
				url: `${ORDERS_URL}/${orderId}/check`,
			}),
			keepUnusedDataFor: 5,
		}),
	}),
});

export const {
	useCreateOrderMutation,
	useGetOrderDetailsQuery,
	usePayOrdersMutation,
	useGetPayPalClientIdQuery,
	useGetMyOrdersQuery,
	useGetManagerOrdersQuery,
	useGetAssistantOrdersQuery,
	useGetSaleOrdersQuery,
	useGetFinanceOrdersQuery,
	useGetBuyerOrdersQuery,
	useGetOrdersQuery,
	useDeliverOrderMutation,
	useDeliverPayOrdersMutation,
	useAcceptOrderMutation,
	useAddAccountsReceivableMutation,
	useCompleteOrderMutation,
	useCheckOrderIdMutation,
	useGetNewIndexOfTodayQuery,
	useGetNewIndexOfTodayBySelectedSaleMutation,
} = ordersApiSlice;
