import React from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';

import genechampLogo from '../assets/genechampLogo.png';

import { useDispatch, useSelector } from 'react-redux'; // dispatch hook
import { Link, useNavigate } from 'react-router-dom'; // navigate
import { logout } from '../slices/authSlice'; // dispatch to logout action

import { resetOrder } from '../slices/orderSlice';
import { useLogoutMutation } from '../slices/usersApiSlice'; // call logout api

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationDropdown from './NotificationDropdown';
// import SearchBox from './navbar/SearchBox';
// import autoCompleteItem from '../data/autocomplete/autocomplete';

const Header = () => {
	const { userInfo } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [logoutApiCall] = useLogoutMutation();

	const logoutHandler = async () => {
		try {
			await logoutApiCall().unwrap();

			dispatch(resetOrder());
			dispatch(logout());
			navigate('/loginout');
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<Navbar id='navbar' bg='primary' variant='light' expand='lg' className='py-1 sticky-kit' collapseOnSelect>
				<Container fluid>
					<Navbar.Brand href='/dashboard'>
						<img src={genechampLogo} alt='PRINGXSOFT' />
					</Navbar.Brand>
					{/* <SearchBox autoCompleteItem={autoCompleteItem} /> */}
					<Navbar.Toggle aria-controls='basic-navbar-nav'>
						<FontAwesomeIcon icon='fa-bars' className='text-white' size='xl' />
					</Navbar.Toggle>

					<Navbar.Collapse id='basic-navbar-nav'>
						<Nav className='ms-auto'>
							{userInfo && (
								<>
									<NotificationDropdown />
									<Dropdown navbar={true} as='li'>
										<Dropdown.Toggle bsPrefix='toggle' as={Link} to='#!' className='pe-0 ps-2 nav-link text-white'>
											{userInfo.name}
										</Dropdown.Toggle>
										<Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
											<div className='bg-white rounded-2 py-2 dark__bg-1000'>
												<Dropdown.Item className='fw-bold text-warning' as={Link} to='/profile'>
													<FontAwesomeIcon icon='crown' className='me-1' />
													<span>个人简介 &amp; 账号</span>
												</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item as={Link} to='/approval/list' className='d-flex align-items-center'>
													<span>审批列表</span>
													{/* <Badge pill bg='danger' className='ms-1 me-2'>
														99+
													</Badge> */}
												</Dropdown.Item>
												{/* <Dropdown.Item as={Link} to='/notificationlist' className='d-flex align-items-center'>
													<span>消息中心</span> */}
												{/* <Badge pill bg='danger' className='ms-1 me-2'>
														99+
													</Badge> */}
												{/* </Dropdown.Item> */}
												{userInfo.canManageUser && (
													<Dropdown.Item as={Link} to='/manager/userlist' className='d-flex align-items-center'>
														<span>用户管理</span>
														{/* <Badge pill bg='danger' className='ms-1 me-2'>
															99+
														</Badge> */}
													</Dropdown.Item>
												)}
												{userInfo.role === 'admin' && (
													<Dropdown.Item as={Link} to='/admin/userlist' className='d-flex align-items-center'>
														<span>系统用户管理</span>
														{/* <Badge pill bg='danger' className='ms-1 me-2'>
															99+
														</Badge> */}
													</Dropdown.Item>
												)}
												{/* <Dropdown.Item as={Link} to='/profile'>
													关于
												</Dropdown.Item> */}
												<Dropdown.Divider />
												<Dropdown.Item onClick={logoutHandler} className='text-danger'>
													<FontAwesomeIcon icon='fa-solid fa-right-from-bracket' className='me-2' />
													退出登录
												</Dropdown.Item>
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default Header;
