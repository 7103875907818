import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, OverlayTrigger, Placeholder, Row, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../../components/common/Background';
import CardDropdown from '../../../components/common/CardDropdown.jsx';
import { clearAssistant } from '../../../slices/orderSlice.js';
import corner1 from './../../../assets/img/illustrations/corner-1.png';
import SelectOrderAssistantModal from './table/SelectOrderAssistantModal.jsx';

const OrderAssistant = () => {
	const order = useSelector((state) => state.order);
	const { watch } = useFormContext();
	const { draftId } = watch();
	const { assistant } = order;
	const [modalShow, setModalShow] = useState(false);
	const dispatch = useDispatch();
	return (
		<>
			<Card className='mb-3'>
				<Background
					image={corner1}
					className='bg-card d-none d-md-block'
					style={{
						borderTopRightRadius: '0.375rem',
						borderBottomRightRadius: '0.375rem',
					}}
				/>
				<Card.Header className='position-relative'>
					<Card.Title>
						<Row className='flex-between-center'>
							<Col md>
								<h6 className='mb-2 mb-md-0'>销售助理</h6>
							</Col>
							<Col xs='auto'>
								<CardDropdown drop='start'>
									<div className='py-2'>
										<Dropdown.Item
											className='text-danger'
											onClick={() => {
												dispatch(clearAssistant());
											}}
										>
											清空
										</Dropdown.Item>
									</div>
								</CardDropdown>
							</Col>
						</Row>
					</Card.Title>
				</Card.Header>
				<Card.Body className='position-relative'>
					{assistant._id ? (
						<>
							<p>
								销售助理: <strong className='border-bottom'>{assistant.name}</strong>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
											重新选择
										</Tooltip>
									}
								>
									<Button variant='falcon-primary' size='sm' className='rounded-pill ms-2 mb-1' type='button' onClick={() => setModalShow(true)}>
										<FontAwesomeIcon icon='fa-arrows-rotate' />
									</Button>
								</OverlayTrigger>
							</p>
						</>
					) : (
						<>
							<Button variant='falcon-primary' size='sm' className='rounded-pill mb-3' type='button' onClick={() => setModalShow(true)}>
								<span className='text-primary'>选择销售助理</span>
								<FontAwesomeIcon icon='fa-solid fa-handshake-angle' className='text-primary fs-9 ms-1' />
							</Button>
						</>
					)}
					{Object.values(assistant).length !== 0 && (
						<>
							<p>
								联系人电话：
								<span>
									{assistant.phone ? (
										<strong>{assistant.phone}</strong>
									) : draftId ? (
										<Placeholder animation='glow' className='mb-2'>
											<Placeholder xs={3} bg='primary' />
										</Placeholder>
									) : (
										<></>
									)}
								</span>
							</p>
							{assistant.email ? (
								<p>
									邮箱：
									<span>
										<strong>{assistant.email}</strong>{' '}
									</span>
								</p>
							) : (
								<></>
							)}
						</>
					)}
				</Card.Body>
			</Card>
			{/* Modal */}
			<SelectOrderAssistantModal modalShow={modalShow} setModalShow={setModalShow} />
		</>
	);
};

export default OrderAssistant;
