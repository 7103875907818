import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import logoQuotaion from '../../../assets/img/logos/quotation-logo.png';
import IconButton from '../../../components/common/IconButton';
import moment from 'moment';

const QuotationHeader = ({ quotationId, draftId }) => {
	return (
		<>
			<Card className='mb-3' id='quotationCard'>
				<Card.Body>
					<Row className='justify-content-between align-items-center'>
						<Col md>
							<h5 className='mb-2 mb-md-0'>报价单编号 # {quotationId ? `${quotationId}` : <></>}</h5>
						</Col>
						<Col xs='auto'>
							<IconButton
								id='editButton'
								variant='light'
								size='md'
								icon='pencil'
								className='me-1 mb-2 mb-sm-0'
								iconClassName='me-1'
								as={Link}
								to={draftId ? `/order/create/${draftId}/draft` : `/order/create/new`}
							>
								继续编辑
							</IconButton>
							<IconButton variant='primary' size='md' icon='print' iconClassName='me-1' className='me-1 mb-2 mb-sm-0' id='printButton' as={Button} onClick={(e) => window.print()}>
								打印
							</IconButton>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

const QuotationScreen = () => {
	const { draftId } = useParams();

	const order = useSelector((state) => state.order);
	const { userInfo } = useSelector((state) => state.auth);
	const { orderId, quotationId, customer, itemsPrice, totalPrice, orderItems, sale } = order;
	return (
		<>
			<QuotationHeader orderId={orderId} quotationId={quotationId} draftId={draftId} />
			<Card>
				<Card.Body>
					<Row className='align-items-center text-center mb-3'>
						<Col sm={6} className='text-sm-start'>
							<img src={logoQuotaion} alt='invoice' width={150} />
						</Col>
						<Col className='text-sm-end mt-3 mt-sm-0'>
							<h2 className='mb-3'>Quotation</h2>
							<h5>上海捷采生物科技有限公司</h5>
							<p className='fs--1 mb-0'>
								中国, 上海市, 徐汇区
								<br /> 龙启路258号, 绿地汇中心 B座 610室
							</p>
						</Col>
						<Col xs={12}>
							<hr />
						</Col>
					</Row>
					<Row className='align-items-center'>
						<Col sm='5'>
							<h6 className='text-500'>客户名称</h6>
							<h5>{customer.customerName}</h5>
							<p className='fs--1'>
								{customer.customerAddress}
								<br />
								{customer.staffName ? customer.staffName : <></>}
								<br />
								{customer.staffEmail ? <a href={`mailto:${customer.staffEmail}`}>{customer.staffEmail}</a> : <></>}
								<br />
								{customer.staffWechat ? customer.staffWechat : <></>}
								<br />
								{customer.staffPhone ? <a href={`tel:${customer.staffPhone}`}>{customer.staffPhone}</a> : <></>}
							</p>
							<h6 className='text-500'>业务对接</h6>
							{sale.name ? (
								<>
									<h5>{sale.name}</h5>
									<p className='fs--1'>
										<a href={`mailto:${sale.email}`}>{sale.email}</a>
										<br />
										<a href={`tel:${sale.phone}`}>{sale.phone}</a>
									</p>
								</>
							) : (
								<>
									<h5>{userInfo.name}</h5>
									<p className='fs--1'>
										<a href={`mailto:${userInfo.email}`}>{userInfo.email}</a>
										<br />
										<a href={`tel:${userInfo.phone}`}>{userInfo.phone}</a>
									</p>
								</>
							)}
						</Col>
						<Col sm='auto' className='ms-auto'>
							<div className='table-responsive'>
								<Table borderless size='sm' className='fs--1'>
									<tbody>
										<tr>
											<th className='text-sm-end'>报价单编号:</th>
											<td>{quotationId ? `${quotationId}` : <></>}</td>
										</tr>
										<tr>
											<th className='text-sm-end'>报价日期:</th>
											<td>{moment().format('YYYY-MM-DD')}</td>
										</tr>

										<tr className='fw-bold'>
											<th className='text-sm-end'>付款方式:</th>
											<td>{order.paymentMethod}</td>
										</tr>
										{order.paymentMethod === '部分支付' && order.paymentDetail.prePaymentOption === '百分比' && (
											<>
												<tr className='alert alert-success fw-bold'>
													<th className='text-sm-end'>预付百分比:</th>
													<td>{order.paymentDetail.prePaymentPercent} %</td>
												</tr>
												<tr className='fw-bold'>
													<th className='text-sm-end'>首次应付:</th>
													<td>¥{((order.paymentDetail.prePaymentPercent / 100) * order.totalPrice).toFixed(2)}</td>
												</tr>
												<tr className='fw-bold'>
													<th className='text-sm-end'>剩余尾款:</th>
													<td>¥{order.paymentDetail.percentBalance}</td>
												</tr>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.paymentDetail.prePaymentOption === '预付款' && (
											<>
												<tr className='fw-bold'>
													<th className='text-sm-end'>预支付:</th>
													<td>¥{order.paymentDetail.prePayment}</td>
												</tr>
												<tr className='fw-bold'>
													<th className='text-sm-end'>剩余尾款:</th>
													<td>¥{order.paymentDetail.prePaymentBalance}</td>
												</tr>
											</>
										)}
										<br />
										<tr className='alert alert-success fw-bold'>
											<th className='text-sm-end'>订单总价:</th>
											<td>¥{order.totalPrice}</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>

					<div className='mt-4 fs--1'>
						<SimpleBarReact>
							<Table striped className='border-bottom'>
								<thead className='light'>
									<tr className='bg-primary text-white dark__bg-1000'>
										<th className='border-0 text-white'>货号</th>
										<th className='border-0 text-white'>产品名称</th>
										<th className='border-0 text-center text-white'>数量</th>
										<th className='border-0 text-end text-white'>售价</th>
										<th className='border-0 text-end text-white'>应付</th>
									</tr>
								</thead>
								<tbody>
									{orderItems.map((item, index) => (
										<tr key={index}>
											<td className='align-middle'>
												<h6 className='mb-0 text-nowrap'>{item.productCode}</h6>
												{/* <p className='mb-0'>Down 35mb, Up 100mb</p> */}
											</td>
											<td className='align-middle'>
												<h6 className='mb-0 text-nowrap'>{item.name}</h6>
												{/* <p className='mb-0'>Down 35mb, Up 100mb</p> */}
											</td>
											<td className='align-middle text-center'>{item.qty}</td>
											<td className='align-middle text-end'>¥{item.sellingPrice}</td>
											<td className='align-middle text-end'>¥{(item.qty * item.sellingPrice).toFixed(2)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</SimpleBarReact>
					</div>

					<Row className='justify-content-end'>
						<Col xs='auto'>
							<Table borderless size='sm' className='fs--1 text-end'>
								<tbody>
									<tr>
										<th className='text-900'>小计:</th>
										<td className='fw-semi-bold'>¥{itemsPrice}</td>
									</tr>
									<tr className='border-top'>
										<th className='text-900'>总计:</th>
										<td className='fw-semi-bold'>¥{totalPrice}</td>
									</tr>
									<tr className='border-top border-top-2 fw-bolder text-900'>
										<th>应付货款:</th>
										<td>¥{totalPrice}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer className='bg-light'>
					<p className='fs--1 mb-0'>
						{order.paymentDetail.warrantyPeriod === 0 ? (
							<span>本报价单所包含设备项无保修期；试剂、配件及服务项目的保修保质时间以实际协商结果为准。</span>
						) : (
							<span>
								本报价单所包含设备项保修期为 <strong>{order.paymentDetail.warrantyPeriod}</strong> 年；试剂、配件及服务项目的保修保质时间以实际协商结果为准。
							</span>
						)}
					</p>
				</Card.Footer>
			</Card>
		</>
	);
};

export default QuotationScreen;
